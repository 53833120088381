<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="160px" class="demo-ruleForm" style="margin-bottom: 0px;">
      <div style="background-color: white;">
        <div class="box">
          <div>
            <span style="color: #285df0;font-size: 19px;font-weight: 600;">基本资料</span>
          </div>
          <el-row class="row">
            <el-col :span="12">
              <div>
                <el-form-item>
                  <template #label><span class="title">机构名称</span></template>
                  <span class="content">{{unitData.name}}</span>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <el-form-item>
                  <template #label><span class="title">公司地址</span></template>
                  <span class="content">{{unitData.address}}</span>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="12">
              <div>
                <el-form-item>
                  <template #label><span class="title">联&ensp;系&ensp;人 </span></template>
                  <span class="content">{{unitData.linkMan}}</span>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <el-form-item>
                  <template #label><span class="title">联系电话</span></template>
                  <span class="content">{{unitData.linkPhone}}</span>
                </el-form-item>
              </div>
            </el-col>
        
          </el-row>

          <hr />
            <div>
              <span style="color: #285df0;font-size: 19px;font-weight: 600;">系统显示</span>
            </div>

            <el-row class="row">
              <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">系统名称</span></template>
                  <el-input v-if="unitData.sysSetData.showChangeSwitch==1" v-model="ruleForm.sysDisplayData.sysName" placeholder="请输入系统名称" maxlength="50" show-word-limit class="input" ></el-input>
                  <span v-if="unitData.sysSetData.showChangeSwitch==0" class="content">{{ruleForm.sysDisplayData.sysName}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <div>
                  <el-form-item>
                    <template #label><span class="title">LOGO</span></template>
                    <div>
                      <ls-file-upload :limitCount="1" :type="2" :autoUpload="false" :files="sysFileList" @onChange="fileOnChange" @deleteFile="sysDeleteFile"></ls-file-upload>
                      <div> <span>(250*120)</span> </div>
                    </div>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>

            <hr />
            <div>
              <span style="color: #285df0;font-size: 19px;font-weight: 600;">登录界面</span>
            </div>

            <el-row class="row">
              <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">系统名称</span></template>
                  <el-input v-if="unitData.infoData.loginInfoChange==1" v-model="ruleForm.entryDisplayData.sysName" placeholder="请输入系统名称" maxlength="50" show-word-limit class="input" ></el-input>
                  <span v-if="unitData.infoData.loginInfoChange==0"  class="content">{{ruleForm.entryDisplayData.sysName}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <div>
                  <el-form-item>
                    <template #label><span class="title">LOGO</span></template>
                    <div>
                      <ls-file-upload :limitCount="1" :type="1" :autoUpload="false" :files="loginFileList" @onChange="fileOnChange"  @deleteFile="loginDeleteFile"></ls-file-upload>
                      <div><span>(250*120)</span></div>
                    </div>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>

          <hr />
          <div>
            <span style="color: #285df0;font-size: 19px;font-weight: 600;">系统资料</span>
          </div>
          <el-row class="row">
            <el-col :span="12">
              <div>
                <el-form-item>
                  <template #label><span class="title">系统开通时间</span></template>
                  <span class="content">{{unitData.sysSetData.beginTime}}</span>
                </el-form-item>
              </div>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="12">
              <div>
                <el-form-item>
                  <template #label><span class="title">系统结束时间</span></template>
                  <span class="content">{{unitData.sysSetData.endTime}}</span>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="12">
              <div>
                <el-form-item>
                  <template #label><span class="title">小程序APPID</span></template>
                  <span class="content">{{unitData.appid}}</span>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <hr />
          <div>
            <span style="color: #285df0;font-size: 19px;font-weight: 600;">账号信息</span>
          </div>
          <el-row class="row">
            <el-col :span="12">
              <div>
                <el-form-item prop="login">
                  <template #label><span class="title">账&emsp;&emsp;&emsp;&emsp;号</span></template>
                  <el-input v-model="ruleForm.login" placeholder="请输入账号" maxlength="25" show-word-limit class="input"></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <!-- <el-row class="row">
              <el-col :span="12">
              <div>
                <el-form-item prop="password">
                  <template #label><span class="title">密&emsp;&emsp;&emsp;&emsp;码</span></template>
                  <el-input v-model="ruleForm.password" placeholder="请输入密码" maxlength="32" show-word-limit class="input"></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row> -->

          <hr />

          <div>
            <span style="color: #285df0;font-size: 19px;font-weight: 600;">授权数量</span>
          </div>

          <el-row class="row">
            <el-col :span="8">
              <div>
                <span class="title">电话授权数量</span>
                <span class="content">{{unitData.resDistributeData.voiceNum}}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span class="title">电话使用数量</span>
                <span class="content">{{unitData.resReduceData.voiceNum}}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span class="title">电话剩余数量</span>
                <span class="content">{{unitData.resDistributeData.voiceNum - unitData.resReduceData.voiceNum}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="8">
              <div>
                <span class="title">短信授权数量</span>
                <span class="content">{{unitData.resDistributeData.smsNum}}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span class="title">短信使用数量</span>
                <span class="content">{{unitData.resReduceData.smsNum}}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span class="title">短信剩余数量</span>
                <span class="content">{{unitData.resDistributeData.smsNum - unitData.resReduceData.smsNum}}</span>
              </div>
            </el-col>
          </el-row>

          <hr />
          <el-row class="row">
            <el-col :span="24">
              <div style="display:flex;align-items: center;">
                <el-button type="primary" style="float:right;" size="small" @click="cancel" plain>返回</el-button>
                <el-button type="primary" style="float:right;" size="small" @click="sure('ruleForm')">提交</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>

    <ls-cropper v-if="cropperVisible" :base64="this.base64"  :type="this.fileType" :width="250" :height="120"  @data="getCopperData" @cancel="closeCropperDialog"></ls-cropper>
  </div>
 
</template>

<script>
import {updateCustomerInfo,uploadLogoFile,deleteLogoFile,uploadLogoFileEx } from '../../../api/api'
import session from '../../../store/store'
import crypto from '../../../until/crypto'
import until from '../../../until/until'

export default {
  data() {
    return {
      cropperVisible:false,
      loginFileList: [],
      loginFileUrl:{},
      // loginFileData: {
      //   customUuid: '',
      //   unitUuid:'',
      //   logoType:1
      // }, //图片上传接口请求参数
      sysFileList: [],
      sysFileUrl:{},
      // sysFileData: {
      //   customUuid: '',
      //   unitUuid:'',
      //   logoType:2
      // }, //图片上传接口请求参数
      base64:'',
      fileType:0,
      loginLogo:{},
      sysLogo:{},

      unitData:{
        sysSetData:{},
        sysDisplayData:{
          sysLogo:''
        },
        resDistributeData:{},
        resReduceData:{},
        infoData:{},
        entryDisplayData:{
          sysLogo:''
        }
      },
      ruleForm: {
        sysDisplayData:{
          sysLogo:''
        },
        entryDisplayData:{
          sysLogo:''
        }
      },
      rules: {
        login: [
          {required: true, message: '请输入账号', trigger: 'blur',}
        ],
      }
    }
  },
  mounted(){
    this.init()
  },
  methods: {
    init() {

    },
    getParentData(data) {
      this.unitData = data;
      this.ruleForm.sysDisplayData = data.sysDisplayData;
      this.ruleForm.entryDisplayData = data.entryDisplayData;
      this.ruleForm.login = data.login;

      this.loginFileUrl.name = data.entryDisplayData.sysName
      this.loginFileUrl.url = data.loginLogoUrl

      this.loginFileList.push(this.loginFileUrl)

      this.sysFileUrl.name = data.sysDisplayData.sysName
      this.sysFileUrl.url = data.sysLogoUrl

      this.sysFileList.push(this.sysFileUrl)
    },
    sure(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.update();
        } else {
          return false;
        }
      });
    },
    update() {
      var param = {
        uuid: session.getLoginInfo().unitUuid,
        parentUnitUuid:session.getLoginInfo().parentUnitUuid,
        login:this.ruleForm.login,
        sysDisplay:crypto.Encrypt(JSON.stringify(this.ruleForm.sysDisplayData)),
        entryDisplay:crypto.Encrypt(JSON.stringify(this.ruleForm.entryDisplayData))
      }
      updateCustomerInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '修改成功',  type: 'success'});
          this.close();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },
    close() {
      this.$emit('update')
    },
    cancel() {
      this.$emit('cancel')
    },

    getUploadUrl() {
      return uploadLogoFile();
    },

    //图片删除
    loginDeleteFile(e, callback) {
      if (until.isNULL(this.loginLogo.logoFileName)) {
          return;
      }
      let param = {
        customUuid: session.getCustomerUuid(),
        unitUuid:session.getLoginInfo().unitUuid,
        logoType:1,
        logoFileName:this.loginLogo.logoFileName,
      };

      deleteLogoFile(param).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("删除成功");
          this.loginLogo = {};
          this.ruleForm.entryDisplayData.sysLogo = "";
          callback(res);
        }else {
          this.$message.error(res.data.message);
        }
      });
    },

    //图片删除
    sysDeleteFile(e, callback) {
      if (until.isNULL(this.sysLogo.logoFileName)) {
          return;
      }
      let param = {
        customUuid: session.getCustomerUuid(),
        unitUuid:session.getLoginInfo().unitUuid,
        logoType:2,
        logoFileName:this.sysLogo.logoFileName,
      };

      deleteLogoFile(param).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("删除成功");
          this.sysLogo = {};
          this.ruleForm.sysDisplayData.sysLogo = "";
          callback(res);
        }else {
          this.$message.error(res.data.message);
        }
      });
    },

    fileOnChange(file,type) {
      this.fileType = type
      const isIMAGE = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png'
      const isLt5M = file.raw.size / 1024 / 1024 < 5
      if (!isIMAGE) {
        this.$message({ showClose: true, message: '请选择 jpg、png 格式的图片',  type: 'warning'})
        // this.sysFileList.splice(0,this.sysFileList.length)
        // this.loginFileList.splice(0,this.loginFileList.length)
        return false
      }
      if (!isLt5M) {
        this.$message({ showClose: true,  message: '图片大小不能超过 5MB',  type: 'warning'})
        // this.sysFileList.splice(0,this.sysFileList.length)
        // this.loginFileList.splice(0,this.loginFileList.length)
        return false
      }
      let reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.cropperVisible = true;
        this.base64 = e.target.result;
      }
    },
    getCopperData(data) {
      let formData = new FormData();
      formData.append('file',data.blob)
      formData.append('customUuid',session.getCustomerUuid())
      formData.append('unitUuid',session.getLoginInfo().unitUuid)
      if(data.type == 1) {
        formData.append('logoType',1)
        this.loginFileUrl.url = data.dataURL;
      }else  if(data.type == 2) {
        formData.append('logoType',2)
        this.sysFileUrl.url = data.dataURL;
      }
      
      uploadLogoFileEx(formData).then((res) => {
        if(res.data.code == 200) {
          this.$message.success("上传成功");
          if(data.type == 1) {
            this.loginLogo = res.data.dataInfo;
            this.ruleForm.entryDisplayData.sysLogo = this.loginLogo.logoFileName;
          }else if(data.type == 2) {
            this.sysLogo = res.data.dataInfo;
            this.ruleForm.sysDisplayData.sysLogo = this.sysLogo.logoFileName;
          }
        }else {
          this.$message.error(res.data.message);
        }
      });
    },
    closeCropperDialog() {
      this.cropperVisible = false;
    },


  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 90%;
}
hr {
  border-color: #f4f4f526;
}
.page {
  padding: 0px 20px;
}
.title {
  margin-right: 25px;
  font-size: 16px;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.box {
  padding: 20px;
  width: 75%;
  background-color: white;
}

.row {
  margin: 20px 0px 20px 0px;
}
</style>